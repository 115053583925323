import { jsx as _jsx } from "react/jsx-runtime";
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
// in case of SkeletonGroup we have just 20px bluring filter(not 20% as in the case of Skeleton component)
const BlurValue = '20px';
const skeletonFrames = keyframes `
    0% {
        transform: translateX(calc(-100% - ${BlurValue}));
    }

    85% {
        transform: translateX(calc(-100% - ${BlurValue}));
    }

    100% {
        transform: translateX(100%);
    }
`;
const SkeletonGroupContainer = styled('div', { shouldForwardProp: propName => propName !== 'animationTime' })(props => ({
    width: 'inherit',
    height: 'auto',
    position: 'relative',
    overflow: 'hidden',
    '& .MuiSkeleton-root::after': {
        animation: 'none',
    },
    '&::after': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        content: '""',
        transform: `translateX(calc(-100% - ${BlurValue}))`,
        animationDuration: props.animationTime,
        animationName: skeletonFrames,
        animationTimingFunction: 'linear',
        animationDelay: '0.5s',
        animationIterationCount: 'infinite',
        background: '#ffffffb3',
        filter: 'blur(20px)',
    }
}));
/**
 * this component synchronizes the animation for several skeletons in one block
 *
 * (the inner skeletons animations would be disabled)
 */
const SkeletonGroup = ({ className = undefined, children, animationTime = '2700ms', isLoading, }) => {
    if (isLoading) {
        return (_jsx(SkeletonGroupContainer, { className: className, animationTime: animationTime, children: children }));
    }
    return children;
};
export default SkeletonGroup;
