import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { ContainerColumn } from '@components/styled';
const LoaderContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'takeFullContainerSpace' && propName !== 'minHeight' })((props) => (Object.assign({ flex: 1, minHeight: props.minHeight, width: 'auto', height: 'auto' }, props.takeFullContainerSpace && ({
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
}))));
const LoadingIndicator = ({ isLoading = false, takeFullContainerSpace = false, minHeight = 'auto', }) => {
    if (!isLoading)
        return null;
    return (_jsx(LoaderContainer, { takeFullContainerSpace: takeFullContainerSpace, minHeight: minHeight, children: _jsx(CircularProgress, {}) }));
};
export default LoadingIndicator;
