import styled from '@emotion/styled';
import { ReactComponent as SuccessIconComponent } from '@icons/wolfkit-solid/checkmark-with-check-circle-solid.svg';
import { ReactComponent as InfoIconComponent } from '@icons/wolfkit-solid/info-circle-solid.svg';
import { ReactComponent as WarningIconComponent } from '@icons/wolfkit-solid/alert-triangle-solid.svg';
import { ReactComponent as ErrorIconComponent } from '@icons/wolfkit-solid/alert-circle-solid.svg';
export const SuccessIcon = styled(SuccessIconComponent)(props => ({
    width: 16,
    height: 16,
    circle: {
        fill: props.displayStyle === 'solid' ? props.theme.palette.text.disabled : props.theme.palette.success.main,
    },
    path: {
        fill: 'transparent',
        stroke: props.displayStyle === 'solid' ? props.theme.palette.success.main : props.theme.palette.text.disabled,
        strokeWidth: '4px',
    },
}));
export const InfoIcon = styled(InfoIconComponent)(props => ({
    width: 16,
    height: 16,
    path: {
        fill: props.displayStyle === 'solid' ? props.theme.palette.text.disabled : props.theme.palette.primary.main,
    },
}));
export const WarningIcon = styled(WarningIconComponent)(props => ({
    width: 16,
    height: 16,
    path: {
        fill: props.displayStyle === 'solid' ? props.theme.palette.text.disabled : props.theme.palette.warning.main,
    },
}));
export const ErrorIcon = styled(ErrorIconComponent)(props => ({
    width: 16,
    height: 16,
    path: {
        fill: props.displayStyle === 'solid' ? props.theme.palette.text.disabled : props.theme.palette.error.main,
    },
}));
