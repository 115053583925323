var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import MuiSkeleton from '@mui/material/Skeleton';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
// block which animated is also has blur-filter with size equal 20% of overall size of component
const BlurValue = 0.2;
const defineBlurFilterValue = (value) => value ? parseFloat(String(value)) * BlurValue : 0;
export const skeletonFrames = ({ height = undefined, size = undefined, }) => keyframes `
    0% {
        transform: translateX(calc(-100% - ${defineBlurFilterValue(height || size)}px));
    }

    85% {
        transform: translateX(calc(-100% - ${defineBlurFilterValue(height || size)}px));
    }

    100% {
        transform: translateX(100%);
    }
`;
const MuiSkeletonStyled = styled(MuiSkeleton, { shouldForwardProp: propName => propName !== 'animationTime' })(props => ({
    transform: 'scale(1,1)',
    backgroundColor: props.theme.palette.primary.light,
    '&::after': {
        background: '#ffffffb3',
        transform: `translateX(calc(-100% - ${defineBlurFilterValue(props.height || props.size)}px))`,
        filter: `blur(${defineBlurFilterValue(props.height || props.size)}px)`,
        animationName: skeletonFrames({ height: props.height, size: props.size }),
        animationDelay: '0.5s',
        animationDuration: props.animationTime,
    },
}));
const Skeleton = (_a) => {
    var { animationTime = '2100ms', size = undefined, height = undefined, width = undefined, variant = 'rounded', animation = 'wave' } = _a, props = __rest(_a, ["animationTime", "size", "height", "width", "variant", "animation"]);
    return _jsx(MuiSkeletonStyled, Object.assign({}, props, { height: height || size, width: width || size, animationTime: animationTime, variant: variant, animation: animation }));
};
export default Skeleton;
