import { useSnackbar } from 'notistack';
const useToastNotification = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const toast = ({ message, variant, displayStyle }) => {
        enqueueSnackbar(message, {
            variant,
            autoHideDuration: null,
            hideIconVariant: false,
            // callback doesnt accept any custom props by typing but it actually works
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            displayStyle,
        });
    };
    const closeToast = (id) => {
        closeSnackbar(id);
    };
    return { toast, closeToast };
};
export default useToastNotification;
